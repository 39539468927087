<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';
import BOGen from '@helper/BOGen';
import PageTitle from '@backend/part/PageTitle.vue';
import swal from 'sweetalert';
import EventBus from '@root/helper/EventBus';

export default {
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
			Name: "",
      PageName: "",
      ObjectName: "",
			AddItem: false,
			data: {data:false},
      filter: {},
      row:{},
      mrValidation:{},
      ajaxRefreshData: null,
    }
	},
  computed:{
    NotFound(){
      if(this.data.data === false) return false
      if(this.data.data.length > 0) return false
      if(this.$route.query.search){
        return "Search with keyword '"+this.$route.query.search+"' not found"
      }
      return this.ObjectName+" not found"
    },
  },
  mounted(){
		if(!Object.keys(this.$root.fields).length) this.refreshVariable()
    if(!this.$root.user.bu_id) this.refreshUser()
		
    this.$set(this.$root, 'page', this)

    setTimeout(()=>{ $(window).trigger("resize") }, 300)
    this.$root.user = Gen.getStorage("user")

    // Set filter
    this.$set(this, 'filter', Gen.clone(Object.assign(this.filter, this.$route.query)))
    
    // Get Bo Metainfo
    if(this.Name){
      this.refreshData(null, {metainfo:true})
    }

    // Logout Callback
    EventBus.$off("logout")
    EventBus.$on("logout", ()=>{
      this.logout()
    })
  },
  methods:{
    /* Helper Methods */
    apiPost(type, data, cb){
      BOGen.apirest("/"+this.Name, Object.assign({type:type}, data), cb, "POST")
    },
    /* To Route Function */
    redirectToList(){
      this.$router.push({name:this.Name})
    },
    redirectToCrud(id="add"){
      this.$router.push({name:this.Name,params:{id:id}})
    },
    /* Refresh Data */
    refreshVariable(){
      var variable = Gen.getStorage("variable", {});
      Object.keys(variable).forEach((k)=>{
        this.$root[k] = variable[k]
      })
      BOGen.apirest("/variable", {}, (err, resp)=>{
        if(err) return;
        Gen.putStorage("variable", resp)
        Object.keys(resp).forEach((k)=>{
          this.$root[k] = resp[k]
        })
      })
    },
    refreshUser(){
      if(!Gen.getStorage('botk')) return;
      BOGen.apirest("/profile", {}, (err, resp)=>{
        if(err) if(err.status == 401){
          return this.logout()
        }
        Gen.putStorage("user", resp)
        this.$root.user = resp
      })
    },
    refreshData(cb, param = {}){
      this.data.data = false
      var params = Object.assign(param, this.$route.query)
      this.ajaxRefreshData = BOGen.apirest("/"+this.Name+(this.$route.params.id?"/"+this.$route.params.id:""), params, (err, resp)=>{
        if(err) return;
        Object.keys(resp).forEach((k)=>{
          this[k] = resp[k]
        })
        if(this.$route.params.id){
          this.row.type = this.$route.params.id == "add" ? "add" : "update"
        }
        if(this.onRefreshPage) return this.onRefreshPage(resp)
        if(cb) cb(resp)
      })
    },
    submitForm(e, callback){
      if(e&&e.btnLoading()) return;
      BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
        console.log(resp.query)
        if(e) e.btnUnloading()
        if(err){
          if(err.status == 422){
            var textError = err.responseJSON[k][0]
            if(textError == 'validation.required') var textError = "Input Required"
            Object.keys(err.responseJSON).forEach((k)=>{
              $("[error="+k+"]").html("<label class='error'>"+textError+"</label>")
            })
          }
          return
        }
        if(resp.error){
          if(this.errorFormCallback) return this.errorFormCallback(resp);
          if(callback) return callback(resp);
          return Gen.info(resp.message, "danger")
        }
        if(resp.success){
          if(this.successFormCallback) return this.successFormCallback(resp);
          if(callback) return callback(resp);
          setTimeout(()=>{
            $('.modal').modal('hide')
          },2000)
          return Gen.info(resp.message, "success", 1000).then(()=>{
            if(typeof resp.query === 'undefined'){
              this.$router.push({name:this.Name})
            } else if(resp.query.length == 0){
              this.$router.push({name:this.Name})
            } else {
              this.$router.push({name:this.Name,query:resp.query})
            }
          })
        }
      },"POST")
    },
    submitFormSlider(e, callback){
      if(e&&e.btnLoading()) return;
      BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
        if(e) e.btnUnloading()
        if(err){
          if(err.status == 422){
            Object.keys(err.responseJSON).forEach((k)=>{
              var textError = err.responseJSON[k][0]
              if(textError == 'validation.required') var textError = "Input Required"
              $("[error="+k+"]").html("<label class='error'>"+textError+"</label>")
            })
          }
          return
        }
        if(resp.error){
          if(this.errorFormCallback) return this.errorFormCallback(resp);
          if(callback) return callback(resp);
          return Gen.info(resp.message, "danger")
        }
        if(resp.success){
          if(this.successFormCallback) return this.successFormCallback(resp);
          if(callback) return callback(resp);
          setTimeout(()=>{
            $('[href="#collapseExample"]').click();
            this.refreshData()
            $('#collapseExample').hide()
          },1500)
          return Gen.info(resp.message, "success", 1500).then(()=>{
            this.$router.push({name:this.Name})
          })
        }
      },"POST")
    },
    /* List Data Function */
    onPaging(page = 1){
      if($(".page-item.active").text() == page) return;
      this.$router.push({name:this.Name, query: Object.assign(Gen.clone(this.$route.query),{page:page})})
      this.refreshData()
    },
    search(){
      var query = Object.assign(Gen.clone(this.$route.query),Gen.clone(this.filter))
      delete query.page
      this.$router.push({name:this.Name, query: query})
    },
    changeStatus(k, active="Active", inactive="Inactive"){
      var v = this.data.data[k]
      var text = (v.status=="Y"?inactive:active)
      swal({
        title: "Change status to "+text+"?",
        text:"",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, change to "+text+"!"],
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          v.status = v.status == "Y" ? "N" : "Y"
          BOGen.apirest("/"+this.Name, {type:"status",id:v.id,status:v.status},(err,resp)=>{
            if(err) return swal(err.resp.message,"","warning")
            swal(resp.message,"","success")
          }, "POST")
        }
      });
    },
    changeStatusId(k, active="Active", inactive="Inactive"){
      var v = k
      var text = (v.status=="Y"?inactive:active)
      swal({
        title: "Change status to "+text+"?",
        text:"",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, change to "+text+"!"],
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          v.status = v.status == "Y" ? "N" : "Y"
          BOGen.apirest("/"+this.Name, {type:"status",id:v,status:v.status},(err,resp)=>{
            if(err) return swal(err.resp.message,"","warning")
            swal(resp.message,"","success")
          }, "POST")
        }
      });
    },
    deleteItem(e,k){
      var v = this.data.data[k]
      swal({
        title: "Delete this "+this.ObjectName+"?",
        text:"",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, Delete "+this.ObjectName+"!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest("/"+this.Name, {type:"delete",id:v.id},(err,resp)=>{
            if(err) return swal(err.resp.message,"","warning")
            if(resp.error) return swal(resp.message,"","warning")
            $(e.target).parents('tr').addClass("animated bounceOutRight");
            setTimeout(()=>{
              $(e.target).parents('tr').removeClass("animated bounceOutRight");
              this.data.data.splice(k, 1);
            }, 1000);
            swal("Deleted!", resp.message, "success");
          }, "POST")
          
        }
      });
    },
    deleteItemId(id,row,object=null){
      console.log(object)
      var textWarning = object ? object : this.ObjectName
      var v = id
      swal({
        title: "Are you sure to delete "+textWarning+"?",
        text:"",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, Sure!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest("/"+this.Name, {type:"delete2",id:v},()=>{
            // callback delete item
          }, "POST")
          $('#'+row).remove()
          setTimeout(()=>{$('.modal').modal('hide')},2000)
          swal("Deleted!", "Information has been deleted.", "success");
          this.refreshData()
        }
      });
    },
    deleteItemCat(id,row){
      console.log(id)
      var v = id
      swal({
        title: "Are you sure to delete category?",
        text:"",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, Sure!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest("/"+this.Name, {type:"deleteCat",id:v},(err,resp)=>{
            // callback delete item
            if(resp.error) return  swal("Deleted!",resp.message, "error");
            $('#'+row).remove()
            swal("Deleted!", "Information has been deleted.", "success");
            this.refreshData()
          }, "POST")
        }
      });
    },
    /* MenuRole */                
    menuRole(name = ""){
      if(this.user.bu_level == 0) return true
      return this.user.menuRole.indexOf(name) > -1
    },
    moduleRole(name = ""){
      if(this.user.bu_level == 0) return true
      return this.user.moduleRole.indexOf(name) > -1
    },
    /* Logout */
    logout(){
      Gen.delStorage("user")
      Gen.delStorage("botk")
      this.$router.push({name:"BoLogin"})
    },
    /* LogoutFe */
    logoutFe(){
      Gen.delStorage("userFe")
      Gen.delStorage("fotk")
      this.$router.push({name:"index"})
    }
  },
  beforeDestroy(){
    if(this.ajaxRefreshData) this.ajaxRefreshData.abort()
  },
  watch:{
    '$route.query'(v){
			this.$set(this, 'filter', Gen.clone(v))
		}
  }
}
</script>
