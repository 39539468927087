<script>
  import Base from '@backend/Base.vue';
  export default {
    extends: Base,
    data() {
      return {
        Name: "BoDashboard",
        site_name: "",
        news: 0,
        info: 0,
        product: 0,
        ask: [],
        viewedProd: [],
        viewedArticles: [],
      }
    },
    computed: {
      ufullname() {
        return this.user ? this.user.bu_full_name : "-"
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {}
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Howdy {{ufullname}}</h5>
            <br> Welcome to the <strong>{{site_name}}</strong> Website Administrator Page
            <br>You are on the PROTECTED page of the <strong>{{site_name}}</strong> Website Content Management
            system.<br><br>
            <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
              Before making arrangements, there are few things to note: <br><br>

              1. Save and secure your Username and Password to avoid things that are not desirable.<br>
              2. Prepare the material or content that will be updated first.<br>
              3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
              4. If there is any questions about using features in this backoffice, you can ask the web developer
              contact
              below.<br>
              5. Use the latest version of the browser to get the compatibility features in this backoffice.<br>
              <p>
                Web Developer Contact :<br>
                Email : hello@lingkar9.com<br>
                Phone : +62-21-744-6929
              </p><br />
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <router-link :to="{name:'BoProduct'}">
          <div class="card bg_1 ">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center round-info"><i class="fas fa-cube"></i></div>
                <div class="ml-md-2 mt-2 mt-lg-0 align-self-center">
                  <p>Total Products</p>
                  <h3>{{product}}</h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Column -->
      <div class="col-lg-4 col-md-6">
        <router-link :to="{name:'BoNews'}">
          <div class="card bg_2">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center round-danger"><i class="fas fa-list-alt"></i></div>
                <div class="ml-md-2 mt-2 mt-lg-0 align-self-center">
                  <p>Total News & Event</p>
                  <h3>{{news}}</h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <!-- Column -->
      <div class="col-lg-4 col-md-6">
        <router-link :to="{name:'BoInfo'}">
          <div class="card bg_3">
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="round align-self-center round-success"><i class="fas fa-paste"></i></div>
                <div class="ml-md-2 mt-2 mt-lg-0 align-self-center">
                  <p>Total Health Info</p>
                  <h3>{{info}}</h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Most Viewed Products</h5>
      </div>
      <div class="card-body">
        <div class="row justify-content-center gutter-20" v-if="mostView.length">
          <div class="col-6 col-md col-xl-3" v-for="(v,k) in mostView" :key="k">
            <div class="item-list collection text-center d-flex flex-column h-100">
              <div class="product-img recipe-cat mb-2 img-mw-200">
                <img :src="uploader(v.ap_img)" class="w-100" :alt="v.ap_name">
              </div>
              <h5 class="card-title mt-3 mb-1 mt-auto">{{v.ap_name}}</h5>
              <strong class="badge badge-success">{{v.ap_view}} Views</strong>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 text-center">No Product</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Most Viewed Health Info</h5>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Title</th>
              <th>Views</th>
              <th>Category</th>
            </thead>
            <tbody v-if="viewedArticles.length">
              <tr v-for="(v1,k1) in viewedArticles" :key="k1">
                <td>{{k1+1}}</td>
                <td>{{v1.an_title}}</td>
                <td>{{v1.an_view ? v1.an_view : 0}}</td>
                <td>{{v1.aic_name}}</td>
              </tr>
            </tbody>
            <tbody v-else> <tr><td colspan="5" align="center">No Content</td></tr></tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>